body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@tailwind base;
@tailwind components;
@tailwind utilities;


p {
  @apply text-gray-600;
}

.sticky {
  @apply fixed bg-white bg-opacity-80;
  backdrop-filter: blur(5px);
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);
}
.collapse.show {
  @apply block;
}

.active > .toggler-icon:nth-child(1) {
  @apply transform rotate-45 top-[7px];
}
.active > .toggler-icon:nth-child(2) {
  @apply opacity-0;
}
.active > .toggler-icon:nth-child(3) {
  @apply top-[-8px] rotate-[135deg];
}
.nav-link {
  @apply inline-block px-5 xl:px-4 lg:px-3 py-2 uppercase text-gray-700 duration-300 hover:text-blue-600;
}
.nav-link.active {
  @apply text-blue-600;
}

.social-link {
  @apply flex justify-center items-center w-10 h-10 bg-blue-600 rounded text-lg text-white duration-300;
}

.tns-nav {
  @apply w-full  text-center;
}
.tns-nav button {
  @apply w-3 h-3 rounded-full bg-white opacity-50 mx-1 duration-300;
}
.tns-nav > .tns-nav-active,
.tns-nav button:hover {
  @apply opacity-100;
}
.tns-nav button:focus {
  @apply outline-none;
}

.form-input {
  @apply w-full mb-5 bg-white border border-gray-500 px-4 py-2 duration-300 outline-none focus:border-blue-600;
}


.carousel-wrapper {
  padding: 0 !important; /* Ensure no padding on the wrapper */
  margin: 0 !important;  /* Ensure no margin on the wrapper */
}

.carousel-slide {
  padding: 0 !important; /* Remove default padding from slides */
  margin: 0 !important;  /* Remove default margin from slides */
}