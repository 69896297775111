.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  transition: ease 1000ms;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}
/* .slideshowSlider {
  white-space: nowrap;
  transition: ease 1000ms;
} */

.swiper-slide img {
  display: block;
  width: 100%;
}

.carousel-wrapper {
  padding: 0 !important; /* Ensure no padding on the wrapper */
  margin: 0 !important;  /* Ensure no margin on the wrapper */
}

.carousel-slide {
  padding: 0 !important; /* Remove default padding from slides */
  margin: 0 !important;  /* Remove default margin from slides */
}
/* input[type=file]::-webkit-file-upload-button,
input[type=file]::file-selector-button {
    @apply text-white bg-gray-700 hover:bg-gray-600 font-medium text-sm cursor-pointer border-0 py-2.5 pl-8 pr-4;
    margin-inline-start: -1rem;
    margin-inline-end: 1rem;
} */

/* @import "node_modules/progress-tracker/src/styles/progress-tracker.scss"; */